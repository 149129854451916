
.App {
  text-align: center;
  overflow-y: hidden;
  font-family: "Pompiere";

}

body, html { 
  font-size: large;
  font-family: "Pompiere";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.startext {
  color: #527495;
  font-family: "Pompiere";
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.banner {
  background-color: yellow;
  text-align: center;
  min-height: 2vh;
}

.warningText {
  font-size: x-large;
  font-family: "Pompiere";
}

.App-header {
  background-color: #fff; 
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  font-size: calc(50px + 2vmin);
  font-family: "Pompiere";
  position: sticky;
  top: 0;
}

.starlogo {
  flex: 1;          /* shorthand for: flex-grow: 1, flex-shrink: 1, flex-basis: 0 */
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header-title {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.iconMenu {
  justify-content: center;
  height: 75vh;
  
}

.clickableArea {
  cursor: pointer;
}

select, text, textarea, input {
  font-family: "Pompiere";
  font-size: large;
}

button { 
  font-family: "Pompiere";
}

.contentArea {
  height: 50vh;
  width: 150vh;
  overflow: scroll;

}

.ciContentArea {
  height: 50vh;
  width: 500;
  overflow: scroll;
  font-size: x-large;

}

.content-wrapper {
  margin: 0 auto;
  max-width: 85%;
}

.site-title {
  color: #c8c8c8;
  font-family: Freestyle Script;
  font-size: 200%;
  margin: 0;
}

.site-title a, .site-title a:hover, .site-title a:active {
  background: none;
  color: #c8c8c8;
  outline: none;
  text-decoration: none;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.footer {
  text-align: center;
  background-color: white;
  color: #527495;
  font-size: 1.8em;
  font-family: "Pompiere"; 
  max-height: 10vh;     
  clear: both;
  padding-top: 8px;
  font-weight: normal;
  margin: 0px;
}

.tabs{
   float: left;
   width: 100%; 
}

.tabs-titles{
  list-style: none;
  padding: 0px;
  margin: 0;
}

.tab-title{
  background-color: #fff;
  display: inline-block;
  padding: 10px;
  color: darkgrey;
  cursor: pointer;
  margin-left:1px;
  font-family: "Pompiere";
  font-size: 150%;
  font-weight: bold;
}

.tab-title--active {
  background-color: #f5f5f5;
  color: #00070a;
}

.tab-content{
  background-color: #efeeef;
  padding: 5px;
  margin: 0;
}


.table{
  border-spacing: 0;
  border: 1px solid black; 
}

.clickable tr {
  cursor: pointer;
}

.warning{
  color: red;
  font-weight: bold;
}

.sideBySide {
  display:flex; flex-direction:row; border:#00070a; border-width: 1;
}

.boxedContent {
  border-style: solid;
}

.item-list {
  display: flex;
  flex-direction: column;
  min-height: 30vh;
  max-height: 30vh;
  overflow-y: scroll;
}

.item-container {
  display: flex;
  align-items: center;
}

.highlighted-balanced {
  background-color: gray;
  color: lightgray;
}

.highlighted-unbalanced {
  background-color: gray;
  font-weight: bold;  
}

.balanced {
  color: lightgray;
}

.unbalanced {
  font-weight: bold; 
}

.some-page-wrapper {
  margin: 15px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.popup {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  
}

.popup__popupContent {
  position: absolute;
  margin: auto;
  overflow: scroll;
  z-index: 9;
  left: 10%;
  right: 10%;
  top: 12%;
  bottom: 12%;
  background: white;
  border: solid 3px #527495;
}

@media screen {
  #printSection {
      display: none;
  }
}
@media print {
  body * {
      visibility:hidden;
  }
  #printSection, #printSection * {
      visibility:visible;
  }
  #printSection {
      position:absolute;
      left:0;
      top:0;
  }
}

.confereeBillEventHeading span {
  text-align: left;
  display: block;
}

.confereeBill {
  width: 100%;
  height: 100%;
  background: white;
  border: 10px;
  border-color: black;
  padding-bottom: 75px;
  align-items: center;
}

#logo	{
	display:block;
	margin-left: auto;
	margin-right: auto;
	max-width: 30%;
}

/* The Category */
.confereeBill p {
  color: black;
  font-weight: bold;
  font-size: 1em;
}

.confereeBillHeader	{
	text-align: center;
}

.confereeBillEvent	{
	text-align: center;
  font-weight: bold;
    
}
	
.confereeBillPartyMembers	{
	margin-left: 5px;
	font-style: italic;
	}

  .confereeBillChargeTotal	{
    font-weight: normal;
    float: right;
    display: block;
    clear: both;
  }
  
  .confereeBillPaymentTotal	{
    font-weight: normal;
    float: right;
    display: block;
    clear: both;
  }
  
  .confereeBillGrandTotal	{
    float: right;
    display: block;
    clear: both;
  }

  .footer	{
    clear: both;
    padding-top: 8px;
    text-align: center;
    font-weight: normal;
      margin: 0px;
    /*margin: 0px 108px;*/
    }
  
  .confereeBill .footer p	{
    font-weight: normal;
    font-style: italic;
    font-size: 0.8em;
  }
  
  .confereeBillFooter {
      margin: 10px;
      margin-top: 25px;
  }

  /*.button, .button:visited {
    border-left: 1px solid #D5DBE5;
    border-right: 1px solid #D5DBE5;
    border-bottom: 1px solid #D5DBE5;
    padding: 5px 20px;
    margin: 0px 0px 5px 0px;
    color: white;
    text-decoration: none;
    font-size: 0.9em;
    font-family: Adobe Jensen Pro, Sans-Serif;
    font-weight: normal;
    text-align: center;
    position: relative;
    /* BACKGROUND GRADIENTS 
    background: #527495;
    background: -moz-linear-gradient(top, #8394B4, #4E6281 50%, #4E6281 51%, #3B4A63);
      /* BORDER RADIUS 
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px;
    border-radius: 7px;
    border-top: 1px solid gray;
    /* TEXT SHADOW 
    text-shadow: 1px 1px 1px black;
    /* BOX SHADOW 
    -moz-box-shadow: 0 1px 3px black;
    -webkit-box-shadow: 0 1px 3px black;
    box-shadow: 0 1px 3px black;
    top: 2px;
    left: 3px;
    min-width: 50px;
    padding: 2px 2px;
    height: 25px;
}*/
	
	/* WHILE HOVERED 
	.button:hover {
        color: #fefbfb;
		background: #527495;;
	 	background: -moz-linear-gradient(top, #4E6281, #455673 50%, #455673 51%, #273141);
	 	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #4E6281), color-stop(.5, #455673), color-stop(.51, #455673), to(#273141));
	}
	
	/* WHILE BEING CLICKED 
	.button:active {
        color: red;
		-moz-box-shadow: 2px 2px 6px black;
		-webkit-box-shadow: 2px 2px 6px black;
	}*/

  .button, .button {
    padding: 10px 35px 20px 35px;
    text-align: center;
    background-color: #005CB8;
    max-height: 5vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    color: #FFFFFF;
    text-transform: uppercase;
    display: inline-block;
    text-decoration: none;
    font-family: "Pompiere", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    -webkit-transition: opacity 250ms ease-out 0s;
    -moz-transition: opacity 250ms ease-out 0s;
    -o-transition: opacity 250ms ease-out 0s;
    transition: opacity 250ms ease-out 0s; }
    .button:hover, .button:hover {
      opacity: .8; }
    .button:disabled {
      background-color: grey;
    }

  .greenButton, .greenButton {
    padding: 10px 35px 20px 35px;
    text-align: center;
    background-color: green;
    max-height: 5vh;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    color: #FFFFFF;
    text-transform: uppercase;
    display: inline-block;
    text-decoration: none;
    font-family: "Pompiere", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    -webkit-transition: opacity 250ms ease-out 0s;
    -moz-transition: opacity 250ms ease-out 0s;
    -o-transition: opacity 250ms ease-out 0s;
    transition: opacity 250ms ease-out 0s; }
    .greenButton:hover, .greenButton:hover {
      opacity: .8; }